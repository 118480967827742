import whoWeAre from "../images/new -images/1-WhoWeAre.jpg";
import Globaly_Coupled_Expert from "../images/new -images/2- Globaly_Coupled_Expert.jpg";
import Our_360_Offerings from "../images/new -images/3- Our_360_Offerings.jpg";
import DVP_Clients from "../images/new -images/4- DVP_Clients.jpg";
import DVP_Partners from "../images/new -images/5- DVP_Partners.jpg";
import Roadmap from "../images/new -images/6- Roadmap.jpg";

const updatedServices = [
  {
    id: 1,
    name: "whoWeAre",
    imgSrc: whoWeAre,
  },
  {
    id: 2,
    name: "Globaly_Coupled_Expert",
    imgSrc: Globaly_Coupled_Expert,
  },
  {
    id: 3,
    name: "Our_360_Offerings",
    imgSrc: Our_360_Offerings,
  },
  {
    id: 4,
    name: "DVP_Clients",
    imgSrc: DVP_Clients,
  },
  {
    id: 5,
    name: "DVP_Partners",
    imgSrc: DVP_Partners,
  },
  {
    id: 6,
    name: "Roadmap",
    imgSrc: Roadmap,
  },
];

export default updatedServices;
